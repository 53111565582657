import React, { lazy } from "react";

const Layout = lazy(() => import("views/layouts"));
const Home = lazy(() => import("views/Home"));
const Ninet = lazy(() => import("views/Ninet"));
const AdviceForMoms = lazy(() => import("views/AdviceMoms"));
const ProductLayout = lazy(() => import("views/Product/layouts"));
const ListProducts = lazy(() => import("views/Product/ListProducts"));
const ListLineProducts = lazy(() => import("views/Product/ListLineProducts"));
const GeneralProductFilter = lazy(() => import("views/Product/GeneralProductFilter"));
const StageLayout = lazy(() => import("views/Stage/layouts"));
const Stage = lazy(() => import("views/Stage"));
const Product = lazy(() => import("views/Product"));
const Contact = lazy(() => import("views/Contact"));

const ListRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/por-que-elegir-ninet",
        element: <Ninet />,
        exact: true,
      },
      {
        path: "/consejos-para-mamis",
        element: <AdviceForMoms />,
        exact: true,
      },
      {
        path: "/contacto",
        element: <Contact />,
        exact: true,
      },
    ],
  },
  {
    path: "/productos",
    element: <ProductLayout />,
    children: [
      {
        path: "/productos/consulta-productos",
        exact: true,
        element: <GeneralProductFilter />,
      },
      {
        path: "/productos/:line_slug",
        exact: true,
        element: <ListProducts />,
      },
      {
        path: "/productos/linea/:line",
        exact: true,
        element: <ListLineProducts />,
      },
      {
        path: "/productos/producto/:slug_product",
        exact: true,
        element: <Product />,
      },
    ],
  },
  {
    path: "/etapas",
    element: <StageLayout />,
    children: [
      {
        path: "/etapas/:slug_stage/:slug_post",
        exact: true,
        element: <Stage />,
      },
    ],
  },
];

export default ListRoutes;
