import React, { createContext, useCallback, useEffect, useState } from "react";
import axiosInstance from "libs/axiosInstance";

export const ContextNavBar = createContext();

const Provider = ({ children }) => {
  const [backgroundNav, setBackgroundNav] = useState("#ff6b00");
  const [openNav, setOpenNav] = useState(false);
  const [menu, setMenu] = useState([]);

  const getMenu = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get("categories");
      setMenu(data);
    } catch (error) {
      console.error("Has error in file ContextNavBar.js");
    }
  }, []);

  useEffect(() => {
    getMenu();
  }, [getMenu]);

  const value = {
    backgroundNav,
    setBackgroundNav,
    openNav,
    setOpenNav,
    menu,
  };
  return <ContextNavBar.Provider value={value}>{children}</ContextNavBar.Provider>;
};

const exporting = {
  Provider,
  Consumer: ContextNavBar.Consumer,
};

export default exporting;
