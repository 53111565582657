import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "swiper/css/bundle";
import ContextNavBar from "context/ContextNavBar";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<div className="loading">Cargando...</div>}>
    <React.StrictMode>
      <BrowserRouter>
        <ContextNavBar.Provider>
          <App />
        </ContextNavBar.Provider>
      </BrowserRouter>
    </React.StrictMode>
  </Suspense>
);
